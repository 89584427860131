import axios from "axios";
import { cloneDeep } from "lodash";
import { setApps } from "reducers/appReducer";
import { parseToken } from "lib/helpers";
import moment from "moment";
import { getAccessToken } from "components/powerBI/components/report/reducers/reportReducer";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  activeReport: {},
  activeWS: {},
  loading: false,
  loaded: false,
  appMenuLoading: false,
  appMenuLoaded: false,
  error: null,
  workspaces: [],
  searchWorkspaces: [],
  newRptSearchWorkspaces: [],
  appMenu: [],
  searchAppMenu: [],
  activeDataset: -1,
  favorites: [],
  recents: [],
  featured: [],
  sFavorites: [],
  sRecents: [],
  sFeatured: [],
  displayRpts: [],
  sDisplayRpts: [],
  originalFeatured: [],
  iconListLoaded: false,
  iconListLoading: false,
  newsFeed: [],
  reportView: "default",
  tokens: null,
  homePageReportingApps: null,
  hPRAppsFolderStatus: null,
  dashboardReports: null
};

/* -------------------- ACTION TYPES -------------------- */
const SET_ACTIVE_RPT = "SET_ACTIVE_RPT";
const SET_WORKSPACES = "SET_WORKSPACES";
const ADD_WORKSPACE_REPORTS = "ADD_WORKSPACE_REPORTS";
// const SET_RPTCFG = "SET_RPTCFG";
const FETCH_REPORT_LIST_BEGIN = "FETCH_REPORT_LIST_BEGIN";
const FETCH_REPORT_LIST_SUCCESS = "FETCH_REPORT_LIST_SUCCESS";
const FETCH_REPORT_LIST_FAILURE = "FETCH_REPORT_LIST_FAILURE";
const FETCH_APP_MENU_BEGIN = "FETCH_APP_MENU_BEGIN";
const FETCH_APP_MENU_SUCCESS = "FETCH_APP_MENU_SUCCESS";
const FETCH_APP_MENU_FAILURE = "FETCH_APP_MENU_FAILURE";
const FETCH_LANDING_PAGE_BEGIN = "FETCH_LANDING_PAGE_BEGIN";
const FETCH_LANDING_PAGE_SUCCESS = "FETCH_LANDING_PAGE_SUCCESS";
const FETCH_LANDING_PAGE_FAILURE = "FETCH_LANDING_PAGE_FAILURE";
const SET_SEARCH_WS = "SET_SEARCH_WS";
const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
const SET_NEW_RPT_SEARCH_WS = "SET_NEW_RPT_SEARCH_WS";
const SET_SEARCH_LANDING_PAGE = "SET_SEARCH_LANDING_PAGE";
const SET_NEWS_FEED = "SET_NEWS_FEED";
const SET_REPORT_VIEW = "SET_REPORT_VIEW";
const SET_ACTIVE_WS = "SET_ACTIVE_WS";
const SET_DISPLAYED_FEATURED_REPORTS = "SET_DISPLAYED_FEATURED_REPORTS";
const GET_RECENT_AND_FAV_REPORTS = "GET_RECENT_AND_FAV_REPORTS";
const SET_FAVORITE_RPT = "SET_FAVORITE_RPT";
const SET_DISPLAYED_REPORTS = "SET_DISPLAYED_REPORTS";
const SET_REPORTING_APPS = "SET_REPORTING_APPS";
const SET_RA_FOLDER_STATUS = "SET_RA_FOLDER_STATUS";
const SET_DASHBOARD_RPTS = "SET_DASHBOARD_RPTS";

/* -------------------- ACTION CREATORS -------------------- */
const doSetReportView = view => {
  return { type: SET_REPORT_VIEW, payload: view };
};
const set_active_rpt = (activeWS, rIndex, dataset) => {
  return {
    type: SET_ACTIVE_RPT,
    activeReport: rIndex,
    activeWS: activeWS,
    activeDataset: dataset
  };
};

const set_active_ws = activeWS => {
  return {
    type: SET_ACTIVE_WS,
    activeWS
  };
};
const set_displayed_featured_reports = featured => {
  return {
    type: SET_DISPLAYED_FEATURED_REPORTS,
    featured
  };
};
const get_recent_and_fav_reports = (recents, favs) => {
  return {
    type: GET_RECENT_AND_FAV_REPORTS,
    recents,
    favs
  };
};

const fetchReportListBegin = () => ({
  type: FETCH_REPORT_LIST_BEGIN
});
// const fetchReportListSuccess = () => {
//   return { type: FETCH_REPORT_LIST_SUCCESS };
// };
const setWorkSpaces = (workspaces, favs, tokens) => {
  return { type: SET_WORKSPACES, payload: workspaces, favorites: favs, tokens };
};
const addWorkSpaceReport = report => {
  return { type: ADD_WORKSPACE_REPORTS, report };
};
const setSearchWorkspace = workspaces => {
  return { type: SET_SEARCH_WS, payload: workspaces };
};
const setSearchLandingPage = (favorites, recents, featured, displayRpts) => {
  return {
    type: SET_SEARCH_LANDING_PAGE,
    favorites,
    recents,
    featured,
    displayRpts
  };
};
const setNewRptSearchWorkspace = workspaces => {
  return { type: SET_NEW_RPT_SEARCH_WS, payload: workspaces };
};

const fetchReportListError = error => ({
  type: FETCH_REPORT_LIST_FAILURE,
  payload: { error }
});

const deleteReportSuccess = workspace => {
  return { type: DELETE_REPORT_SUCCESS, payload: workspace };
};
const fetchLandingPageBegin = () => ({
  type: FETCH_LANDING_PAGE_BEGIN
});
const fetchLandingPageSuccess = (featured, sasTokens) => {
  return {
    type: FETCH_LANDING_PAGE_SUCCESS,
    featured,
    tokens: sasTokens
  };
};
const fetchAppMenuError = () => ({
  type: FETCH_APP_MENU_FAILURE
});
const fetchAppMenuBegin = () => ({
  type: FETCH_APP_MENU_BEGIN
});
// const fetchAppMenuSuccess = menu => {
//   return {
//     type: FETCH_APP_MENU_SUCCESS,
//     payload: menu
//   };
// };
const fetchLandingPageError = () => ({
  type: FETCH_LANDING_PAGE_FAILURE
});

const setNewsFeed = newsFeed => {
  return { type: SET_NEWS_FEED, newsFeed };
};
const setFavorite = favorite => {
  return { type: SET_FAVORITE_RPT, payload: favorite };
};
const setReportingApps = apps => {
  return { type: SET_REPORTING_APPS, payload: apps };
};
const setDashboardReports = rpts => {
  return { type: SET_DASHBOARD_RPTS, payload: rpts };
};
// const setRAFolderStatus = folderStatus => {
//   return { type: SET_RA_FOLDER_STATUS, payload: folderStatus };
// };

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const getReports = (reportGroup = "", ws = "", rpt = "", showFilterPane = false) => {
  return dispatch => {
    dispatch(fetchReportListBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/getUpdateRptTimes`,
        { reportGroup },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        // console.log(res);
      });
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/reportList/getReports`,
        { reportGroup },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        if (ws !== "") {
          axios
            .post(
              `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/updatePermissions`,
              {
                groupId: ws,
                reportId: rpt,
                showFilterPane
                //idToken: localStorage.getItem("id_token")
              },
              {
                headers: {
                  ClientToken: localStorage.getItem("clientToken")
                }
              }
            )
            .then(result => {
              dispatch(addWorkSpaceReport(result.data.response));
            });
        } else {
          dispatch(setWorkSpaces(response.data.workspaces, response.data.favorites, response.data.sasTokens));

          dispatch(setApps(response.data.workspaces));
        }
        dispatch(setDashboardReports(response.data.dashboardRpts));
        dispatch(setReportingApps(response.data.homepageApps));
        if (response.data.homepageApps !== undefined && response.data.homepageApps !== null) {
          axios
            .get(
              `${process.env.REACT_APP_BIGHORN_SERVER}/api/app/getHomePageAppStatus`,

              {
                headers: {
                  ClientToken: localStorage.getItem("clientToken")
                }
              }
            )
            .then(response => {
              // dispatch(setRAFolderStatus(response.data.homepageAppOpenStatus));
            });
        }
        //dispatch(fetchReportListSuccess());
      })
      .catch(error => {
        dispatch(fetchReportListError(error));
      });
  };
};
export const getAppMenu = () => {
  return dispatch => {
    dispatch(fetchAppMenuBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/reportList/getAppMenu`,
        {},
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(setWorkSpaces(response.data.workspaces));
        dispatch(setApps(response.data.workspaces));
      })
      .catch(err => {
        dispatch(fetchAppMenuError());
      });
  };
};
export const getLandingPage = () => {
  return dispatch => {
    dispatch(fetchLandingPageBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/reportList/getLandingPage`,
        {},
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        let sasTokens = response.data.sasTokens;
        // let favs = response.data.favorites.map(fav => {
        //   if (fav.img !== "") {
        //     let ws = fav.workspace.name
        //       .toLowerCase()
        //       .replace(/[^a-zA-Z0-9]*/g, "");
        //     sasTokens.forEach(token => {
        //       if (token.workspaceName === ws) {
        //         fav.img = fav.img + "?" + token.sasToken;
        //       }
        //     });
        //   }
        //   return fav;
        // });

        // let recent = response.data.recents.map(rec => {
        //   if (rec.img !== "") {
        //     let ws = rec.workspace.name
        //       .toLowerCase()
        //       .replace(/[^a-zA-Z0-9]*/g, "");
        //     sasTokens.forEach(token => {
        //       if (token.workspaceName === ws) {
        //         rec.img = rec.img + "?" + token.sasToken;
        //       }
        //     });
        //   }
        //   return rec;
        // });
        let featured = response.data.featured.map(feat => {
          if (feat.img !== "") {
            let ws = feat.workspace.name.toLowerCase().replace(/[^a-zA-Z0-9]*/g, "");

            sasTokens.forEach(token => {
              if (token.workspaceName === ws && !feat.img.includes("?st=")) {
                feat.img = feat.img + "?" + token.sasToken;
              } else if (token.workspaceName === ws) {
                feat.img = feat.img.split("?st=")[0] + "?" + token.sasToken;
              }
            });
          }
          return feat;
        });

        dispatch(fetchLandingPageSuccess(featured, sasTokens));
      })
      .catch(error => {
        dispatch(fetchLandingPageError(error));
      });
  };
};

export const saveFavorite = (fav, remove) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/reportList/saveFavorites`,
        {
          fav,
          remove
          //user: localStorage.getItem("user")
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(result => {
        dispatch(setFavorite(fav));
      })
      .catch(err => {
        console.log(err);
      });
  };
};
export const addRecent = recent => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/reportList/addRecents`,
        {
          recent
          //user: localStorage.getItem("user")
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(result => {
        dispatch(getLandingPage());
      });
  };
};

export const saveUserReportView = (reportId, view) => {
  return dispatch => {
    dispatch(doSetReportView(view));
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/saveUserReportView`,
        {
          reportId,
          view
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {})
      .catch(error => console.log(error));
  };
};
export const getReportViewForUser = reportId => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/getReportViewForUser`,
        {
          reportId
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        if (response.data.override !== undefined) {
          dispatch(doSetReportView(response.data.override.view));
        }
      })
      .catch(error => console.log(error));
  };
};
export const updatePermissions = (ws = "", rpt = "", showFilterPane = false) => {
  return dispatch => {
    dispatch(fetchReportListBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/updatePermissions`,
        {
          groupId: ws,
          reportId: rpt,
          showFilterPane
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(result => {
        axios.post(
          `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/getWorkspacesAndReportsByName`,
          {
            //clientToken: localStorage.getItem("clientToken")
          },
          {
            headers: {
              ClientToken: localStorage.getItem("clientToken")
            }
          }
        );
      })
      .then(response => {
        dispatch(setWorkSpaces(response.data.workspaces));
        dispatch(setApps(response.data.workspaces));
      })
      .catch(error => {
        dispatch(fetchReportListError(error));
      });
  };
};

export const filterReports = (filter, workspaces) => {
  return dispatch => {
    if (filter === "") {
      dispatch(setSearchWorkspace(workspaces));
    } else {
      let newWs = cloneDeep(workspaces);

      let date;
      if (filter.includes("|||")) {
        let filterArr = filter.split("|||");
        filter = filterArr[0];
        date = filterArr[1];
      }
      let dateField, dateStr;
      if (date === "null" || date === undefined) {
        dispatch(
          setSearchWorkspace(
            newWs.map(workspace => {
              if (workspace.children === undefined) {
                workspace.reports = workspace.reports.filter(report => {
                  return report.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
                });
              } else {
                workspace.children = workspace.children.filter((file, index) => {
                  if (file.children === undefined) {
                    if (file.description !== undefined && file.description !== null) {
                      return (
                        file.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                        file.description.toLowerCase().indexOf(filter.toLowerCase()) !== -1
                      );
                    } else {
                      return file.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
                    }
                  } else {
                    file.children = file.children.filter(child => {
                      if (child.fileType === "folder") {
                        child.children = child.children.filter(gChild => {
                          if (gChild.description !== undefined && gChild.description !== null) {
                            return (
                              gChild.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                              gChild.description.toLowerCase().indexOf(filter.toLowerCase()) !== -1
                            );
                          } else {
                            return gChild.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
                          }
                        });
                        if (child.children.length > 0) {
                          return true;
                        } else {
                          return false;
                        }
                      } else {
                        if (child.description !== undefined && child.description !== null) {
                          return (
                            child.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                            child.description.toLowerCase().indexOf(filter.toLowerCase()) !== -1
                          );
                        } else {
                          return child.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
                        }
                      }
                    });
                    if (file.children.length > 0) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                });
              }

              return workspace;
            })
          )
        );
      } else if (filter === "") {
        dateStr = date.split(" ")[3];
        if (date.includes("Created Date")) {
          dateField = "pbiCreatedDate";
        } else {
          dateField = "pbiModifiedDate";
        }
        if (date.includes("After")) {
          dispatch(
            setSearchWorkspace(
              newWs.map(workspace => {
                if (workspace.children === undefined) {
                  workspace.reports = workspace.reports.filter(report => {
                    return moment(report[dateField]).isAfter(dateStr);
                  });
                } else {
                  workspace.children = workspace.children.filter((file, index) => {
                    if (file.children === undefined) {
                      if (file[dateField] !== undefined && file[dateField] !== null) {
                        return moment(file[dateField]).isAfter(dateStr);
                      } else {
                        return false;
                      }
                    } else {
                      file.children = file.children.filter(child => {
                        if (child.fileType === "folder") {
                          child.children = child.children.filter(gChild => {
                            if (gChild[dateField] !== undefined && gChild[dateField] !== null) {
                              return moment(gChild[dateField]).isAfter(dateStr);
                            } else {
                              return false;
                            }
                          });
                          if (child.children.length > 0) {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          if (child[dateField] !== undefined && child[dateField] !== null) {
                            return moment(child[dateField]).isAfter(dateStr);
                          } else {
                            return false;
                          }
                        }
                      });
                      if (file.children.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  });
                }

                return workspace;
              })
            )
          );
        } else {
          dispatch(
            setSearchWorkspace(
              newWs.map(workspace => {
                if (workspace.children === undefined) {
                  workspace.reports = workspace.reports.filter(report => {
                    return moment(report[dateField]).isBefore(dateStr);
                  });
                } else {
                  workspace.children = workspace.children.filter((file, index) => {
                    if (file.children === undefined) {
                      if (file[dateField] !== undefined && file[dateField] !== null) {
                        return moment(file[dateField]).isBefore(dateStr);
                      } else {
                        return false;
                      }
                    } else {
                      file.children = file.children.filter(child => {
                        if (child.fileType === "folder") {
                          child.children = child.children.filter(gChild => {
                            if (gChild[dateField] !== undefined && gChild[dateField] !== null) {
                              return moment(gChild[dateField]).isBefore(dateStr);
                            } else {
                              return false;
                            }
                          });
                          if (child.children.length > 0) {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          if (child[dateField] !== undefined && child[dateField] !== null) {
                            return moment(child[dateField]).isBefore(dateStr);
                          } else {
                            return false;
                          }
                        }
                      });
                      if (file.children.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  });
                }

                return workspace;
              })
            )
          );
        }
      } else {
        dateStr = date.split(" ")[3];
        if (date.includes("Created Date")) {
          dateField = "pbiCreatedDate";
        } else {
          dateField = "pbiModifiedDate";
        }
        if (date.includes("After")) {
          dispatch(
            setSearchWorkspace(
              newWs.map(workspace => {
                if (workspace.children === undefined) {
                  workspace.reports = workspace.reports.filter(report => {
                    return (
                      moment(report[dateField]).isAfter(dateStr) &&
                      report.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
                    );
                  });
                } else {
                  workspace.children = workspace.children.filter((file, index) => {
                    if (file.children === undefined) {
                      if (file[dateField] !== undefined && file[dateField] !== null) {
                        return (
                          moment(file[dateField]).isAfter(dateStr) &&
                          file.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
                        );
                      } else {
                        return false;
                      }
                    } else {
                      file.children = file.children.filter(child => {
                        if (child.fileType === "folder") {
                          child.children = child.children.filter(gChild => {
                            if (gChild[dateField] !== undefined && gChild[dateField] !== null) {
                              return (
                                moment(gChild[dateField]).isAfter(dateStr) &&
                                gChild.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
                              );
                            } else {
                              return false;
                            }
                          });
                          if (child.children.length > 0) {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          if (child[dateField] !== undefined && child[dateField] !== null) {
                            return (
                              moment(child[dateField]).isAfter(dateStr) &&
                              child.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
                            );
                          } else {
                            return false;
                          }
                        }
                      });
                      if (file.children.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  });
                }

                return workspace;
              })
            )
          );
        } else {
          dispatch(
            setSearchWorkspace(
              newWs.map(workspace => {
                if (workspace.children === undefined) {
                  workspace.reports = workspace.reports.filter(report => {
                    return moment(report[dateField]).isBefore(dateStr);
                  });
                } else {
                  workspace.children = workspace.children.filter((file, index) => {
                    if (file.children === undefined) {
                      if (file[dateField] !== undefined && file[dateField] !== null) {
                        return moment(file[dateField]).isBefore(dateStr);
                      } else {
                        return false;
                      }
                    } else {
                      file.children = file.children.filter(child => {
                        if (child.fileType === "folder") {
                          child.children = child.children.filter(gChild => {
                            if (gChild[dateField] !== undefined && gChild[dateField] !== null) {
                              return moment(gChild[dateField]).isBefore(dateStr);
                            } else {
                              return false;
                            }
                          });
                          if (child.children.length > 0) {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          if (child[dateField] !== undefined && child[dateField] !== null) {
                            return moment(child[dateField]).isBefore(dateStr);
                          } else {
                            return false;
                          }
                        }
                      });
                      if (file.children.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  });
                }

                return workspace;
              })
            )
          );
        }
      }
    }
  };
};

export const filterLandingPage = (filter, fav, recents, featured, displayRpts) => {
  return dispatch => {
    if (filter === "") {
      dispatch(setSearchLandingPage(fav, recents, featured, displayRpts));
    } else {
      let date;
      if (filter.includes("|||")) {
        let filterArr = filter.split("|||");
        filter = filterArr[0];
        date = filterArr[1];
      }

      let newFavs = cloneDeep(fav);
      let newRecents = cloneDeep(recents);
      let newFeatured = cloneDeep(featured);
      let newDisplayRpts = cloneDeep(displayRpts);
      let dateField;
      if (date === "null" || date === undefined) {
        dispatch(
          setSearchLandingPage(
            newFavs.filter(nFav => {
              return nFav.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
            }),
            newRecents.filter(nRecents => {
              return nRecents.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
            }),
            newFeatured.filter(nFeatured => {
              return nFeatured.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
            }),
            newDisplayRpts.filter(nDisplay => {
              return nDisplay.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
            })
          )
        );
      } else if (filter === "") {
        if (date.includes("Created Date")) {
          dateField = "pbiCreatedDate";
        } else {
          dateField = "pbiModifiedDate";
        }
        if (date.includes("After")) {
          dispatch(
            setSearchLandingPage(
              newFavs.filter(nFav => {
                if (nFav[dateField] === undefined) {
                  return false;
                }
                return moment(nFav[dateField]).isAfter(date.split(" ")[3]);
              }),
              newRecents.filter(nRecents => {
                if (nRecents[dateField] === undefined) {
                  return false;
                }
                return moment(nRecents[dateField]).isAfter(date.split(" ")[3]);
              }),
              newFeatured.filter(nFeatured => {
                if (nFeatured[dateField] === undefined) {
                  return false;
                }
                return moment(nFeatured[dateField]).isAfter(date.split(" ")[3]);
              }),
              newDisplayRpts.filter(nDisplay => {
                if (nDisplay[dateField] === undefined) {
                  return false;
                }
                return moment(nDisplay[dateField]).isAfter(date.split(" ")[3]);
              })
            )
          );
        } else {
          dispatch(
            setSearchLandingPage(
              newFavs.filter(nFav => {
                if (nFav[dateField] === undefined) {
                  return false;
                }
                return moment(nFav[dateField]).isBefore(date.split(" ")[3]);
              }),
              newRecents.filter(nRecents => {
                if (nRecents[dateField] === undefined) {
                  return false;
                }
                return moment(nRecents[dateField]).isBefore(date.split(" ")[3]);
              }),
              newFeatured.filter(nFeatured => {
                if (nFeatured[dateField] === undefined) {
                  return false;
                }
                return moment(nFeatured[dateField]).isBefore(date.split(" ")[3]);
              }),
              newDisplayRpts.filter(nDisplay => {
                if (nDisplay[dateField] === undefined) {
                  return false;
                }
                return moment(nDisplay[dateField]).isBefore(date.split(" ")[3]);
              })
            )
          );
        }
      } else {
        if (date.includes("Created Date")) {
          dateField = "pbiCreatedDate";
        } else {
          dateField = "pbiModifiedDate";
        }
        let dateStr = date.split(" ")[3];
        if (date.includes("After")) {
          dispatch(
            setSearchLandingPage(
              newFavs.filter(nFav => {
                if (nFav[dateField] === undefined) {
                  return false;
                }
                return nFav.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 && moment(nFav[dateField]).isAfter(dateStr);
              }),
              newRecents.filter(nRecents => {
                if (nRecents[dateField] === undefined) {
                  return false;
                }
                return (
                  nRecents.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 &&
                  moment(nRecents[dateField]).isAfter(dateStr)
                );
              }),
              newFeatured.filter(nFeatured => {
                if (nFeatured[dateField] === undefined) {
                  return false;
                }
                return (
                  nFeatured.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 &&
                  moment(nFeatured[dateField]).isAfter(dateStr)
                );
              }),
              newDisplayRpts.filter(nDisplay => {
                if (nDisplay[dateField] === undefined) {
                  return false;
                }
                return (
                  nDisplay.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 &&
                  moment(nDisplay[dateField]).isAfter(dateStr)
                );
              })
            )
          );
        } else {
          dispatch(
            setSearchLandingPage(
              newFavs.filter(nFav => {
                if (nFav[dateField] === undefined) {
                  return false;
                }
                return (
                  nFav.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 && moment(nFav[dateField]).isBefore(dateStr)
                );
              }),
              newRecents.filter(nRecents => {
                if (nRecents[dateField] === undefined) {
                  return false;
                }
                return (
                  nRecents.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 &&
                  moment(nRecents[dateField]).isBefore(dateStr)
                );
              }),
              newFeatured.filter(nFeatured => {
                if (nFeatured[dateField] === undefined) {
                  return false;
                }
                return (
                  nFeatured.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 &&
                  moment(nFeatured[dateField]).isBefore(dateStr)
                );
              }),
              newDisplayRpts.filter(nDisplay => {
                if (nDisplay[dateField] === undefined) {
                  return false;
                }
                return (
                  nDisplay.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 &&
                  moment(nDisplay[dateField]).isBefore(dateStr)
                );
              })
            )
          );
        }
      }
    }
  };
};

export const filterDatasets = (filter, workspaces) => {
  return dispatch => {
    if (filter === "") {
      dispatch(setNewRptSearchWorkspace(workspaces));
    } else {
      let newWs = cloneDeep(workspaces);
      dispatch(
        setNewRptSearchWorkspace(
          newWs.map(workspace => {
            workspace.datasets = workspace.datasets.filter(dataset => {
              return dataset.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
            });
            return workspace;
          })
        )
      );
    }
  };
};

export const setActiveReport = (wIndex, rIndex, dataset = -1) => {
  return dispatch => {
    dispatch(set_active_rpt(wIndex, rIndex, dataset));

    if (rIndex !== undefined && rIndex !== null && Object.entries(rIndex).length > 0) {
      let clientToken = localStorage.getItem("clientToken");
      let groups = parseToken(clientToken).groups;
      let creator = false;
      let editor = false;
      let mode = "";
      let createMode = [];
      let editMode = [];
      if (groups !== undefined) {
        createMode = groups.filter(group => {
          return group === "pbi-creator";
        });
        editMode = groups.filter(group => {
          return group === "pbi-editor";
        });
      }

      if (createMode.length >= 1) {
        creator = true;
      }

      if (editMode.length >= 1 || createMode.length >= 1) {
        editor = true;
      }
      if (editor || creator) {
        mode = "Edit";
      } else {
        mode = "View";
      }
      let workspace = wIndex.workspaceId;
      if (workspace === undefined) {
        if (rIndex.workspace === undefined) {
          workspace = rIndex.workspaceId;
        } else {
          workspace = rIndex.workspace.workspaceId;
        }
      }

      if (rIndex.type === "powerBI" || rIndex.type === "fileGroupings") {
        dispatch(getAccessToken(workspace, rIndex.id, rIndex.datasetId, mode));
      }
    }
  };
};
export const setActiveWS = wIndex => {
  return dispatch => {
    dispatch(set_active_ws(wIndex));
  };
};

export const deleteReport = (groupId, reportId) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/deleteReport`,
        {
          groupId,
          reportId
          // user: localStorage.getItem("user")
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(deleteReportSuccess(response.data.response));
        dispatch(getReports());
      })
      .catch(err => {
        console.log(err);
        dispatch(getReports());
      });
  };
};
export const getNewsFeed = () => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/reportList/getNewsFeed`,
        {
          //groupId,
          //reportId
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        function count(main_str, sub_str) {
          main_str += "";
          sub_str += "";

          if (sub_str.length <= 0) {
            return main_str.length + 1;
          }

          let subStr = sub_str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
          return (main_str.match(new RegExp(subStr, "gi")) || []).length;
        }
        let sasToken = response.data.sasClientToken;
        let newsfeed = [];
        response.data.newsFeed.forEach(nf => {
          let rmCount = count(nf.html, "src='https://");
          let x = 1;

          while (x <= rmCount) {
            let img = nf.html.split("src='")[x].split("'")[0];

            if (img.includes("allclients-staticimages")) {
              nf.html = nf.html.replace(img, img + "?" + response.data.sasAllToken);
            } else {
              nf.html = nf.html.replace(img, img + "?" + sasToken);
            }
            x = x + 1;
          }
          newsfeed.push(nf);
        });
        dispatch(setNewsFeed(newsfeed));
      });
  };
};

export const setDisplayedFeaturedReports = featured => {
  return dispatch => {
    dispatch(set_displayed_featured_reports(featured));
  };
};
export const getRecentAndFavReports = workspace => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/reportList/getRecentsAndFavsForWorkspace`,
        {
          workspace
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(get_recent_and_fav_reports(response.data.recents, response.data.favs));
      });
  };
};
export const clearRecents = workspace => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/reportList/clearRecents`,
        {
          workspace
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getRecentAndFavReports(workspace));
      });
  };
};
export const setDisplayedRpts = reports => {
  return dispatch => {
    dispatch({ type: SET_DISPLAYED_REPORTS, reports });
  };
};

function orderItems(collection, workspaces, activeWS) {
  let col = cloneDeep(collection);
  if (collection.length > 0) {
    let order = [];
    workspaces.forEach(item => {
      if (item._id === activeWS) {
        order = item.order;
      }
    });
    if (order !== undefined && order.length > 0) {
      var e = [];

      order.forEach(el =>
        e.push(
          collection.find(e => {
            return e._id === el;
          })
        )
      );

      e = e.filter(item => {
        return item !== undefined;
      });
      // appsOrder = sortBy(appsOrder, [
      //   function (o) {
      //     return o.appOrder;
      //   }
      // ]);
      collection = e;
    }
  }
  if (collection.length < col.length) {
    let collectionArr = collection.map(item => {
      return item._id;
    });
    col.forEach(item => {
      if (!collectionArr.includes(item._id)) {
        collection.push(item);
      }
    });
  }

  return collection;
}
/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_REPORT_LIST_BEGIN:
      return { ...state, loading: true, loaded: false, error: null };
    case FETCH_REPORT_LIST_SUCCESS:
      return { ...state, loading: false, loaded: true };
    case FETCH_REPORT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload.error,
        reportConfig: []
      };
    case SET_WORKSPACES:
      let nTokens = cloneDeep(state.tokens);
      if (nTokens === null) {
        nTokens = [];
      }
      let tVals = nTokens.map(item => {
        return item.workspaceName;
      });
      action.tokens.forEach(item => {
        if (!tVals.includes(item.workspaceName)) {
          nTokens.push(item);
        }
      });

      let newWS = action.payload.map(item => {
        if (item.type === "customApplication") {
          let nChild = [];
          if (item.groupings.length > 0) {
            nChild = [...item.groupings];
          }
          if (item.applications.length > 0) {
            for (var nApp of item.applications) {
              nApp.type = nApp.title;
              nChild.push(nApp);
            }
          }
          if (item.reports.length > 0) {
            for (var nRep of item.reports) {
              nChild.push(nRep);
            }
          }

          var c = [];
          item.order.forEach(el => {
            c.push(
              nChild.find(e => {
                if (e.type === "Files") {
                  return e.type === el;
                } else if (e.type === "grouping") {
                  return e.id === el;
                } else if (e.type === "powerBI" || e.type === "tableau" || e.type === "staticContent") {
                  return e._id === el;
                }
                return false;
              })
            );
          });

          c = c.filter(item => {
            return item !== undefined;
          });

          item.children = c;
        } else if (item.type === "tableau") {
          item.children = item.reports;
        }

        return item;
      });

      return {
        ...state,
        //sFavorites: action.favorites,
        workspaces: newWS,
        searchWorkspaces: newWS,
        newRptSearchWorkspaces: newWS,
        loading: false,
        loaded: true,
        tokens: nTokens
      };
    case ADD_WORKSPACE_REPORTS:
      let newWorkspaces = state.workspaces;
      state.activeWS.reports.push(action.report);

      return {
        ...state,
        workspaces: newWorkspaces,
        searchWorkspaces: newWorkspaces,
        newRptSearchWorkspaces: newWorkspaces,
        loading: false,
        loaded: true
      };
    case SET_SEARCH_WS:
      return {
        ...state,
        searchWorkspaces: action.payload
      };
    case SET_NEW_RPT_SEARCH_WS:
      return {
        ...state,
        newRptSearchWorkspaces: action.payload
      };
    case SET_ACTIVE_RPT:
      let activeDataset = action.activeDataset;
      if (activeDataset === -1 && action.activeReport !== undefined && action.activeReport.datasetId !== -1) {
        activeDataset = action.activeReport.datasetId;
      }

      return {
        ...state,
        activeReport: action.activeReport,
        activeWS: action.activeWS,
        reportView: "default",
        activeDataset
      };
    case SET_ACTIVE_WS:
      return {
        ...state,
        activeWS: action.activeWS
      };
    case DELETE_REPORT_SUCCESS:
      let updatedWorkspaces = state.workspaces.map(workspace => {
        if (workspace._id === action.payload._id) {
          action.payload.datasets = workspace.datasets;
          workspace = action.payload;
        }
        return workspace;
      });

      return {
        ...state,
        searchWorkspaces: updatedWorkspaces,
        workspaces: updatedWorkspaces,
        newRptSearchWorkspaces: updatedWorkspaces
      };
    case SET_SEARCH_LANDING_PAGE:
      let newRecs = cloneDeep(action.recents);
      let aFav = cloneDeep(action.favorites);
      let neFeatured = cloneDeep(action.featured);
      newRecs = orderItems(newRecs, state.workspaces, state.activeWS);
      aFav = orderItems(aFav, state.workspaces, state.activeWS);
      neFeatured = orderItems(neFeatured, state.workspaces, state.activeWS);

      return {
        ...state,
        sFavorites: aFav,
        sRecents: newRecs,
        sFeatured: neFeatured,
        sDisplayRpts: action.displayRpts
      };
    case FETCH_LANDING_PAGE_BEGIN:
      return { ...state, iconListLoaded: false, iconListLoading: true };
    case SET_FAVORITE_RPT:
      let newFavs = cloneDeep(state.favorites);
      let favCheck = [];
      if (action.payload.id.includes("-")) {
        favCheck = newFavs.map(item => {
          return item.id;
        });
      } else {
        favCheck = newFavs.map(item => {
          return item._id;
        });
      }
      if (!favCheck.includes(action.payload.id)) {
        newFavs.push({ id: action.payload.id, _id: action.payload._id });
      } else {
        let idx = -1;
        newFavs.forEach((item, index) => {
          if (action.payload.id.includes("-")) {
            if (item.id === action.payload.id) {
              idx = index;
            }
          } else if (item._id === action.payload.id) {
            idx = index;
          }
        });

        newFavs.splice(idx, 1);
      }
      newFavs = orderItems(newFavs, state.workspaces, state.activeWS);
      return {
        ...state,
        sFavorites: newFavs,
        favorites: newFavs
      };
    case FETCH_LANDING_PAGE_SUCCESS:
      //featured can get set before this returns success ... do not void those changes

      let newTokens = cloneDeep(state.tokens);
      if (newTokens === null) {
        newTokens = [];
      }
      let tokenVals = newTokens.map(item => {
        return item.workspaceName;
      });
      action.tokens.forEach(item => {
        if (!tokenVals.includes(item.workspaceName)) {
          newTokens.push(item);
        }
      });

      if (state.featured.length > 0) {
        return {
          ...state,
          // sFavorites: action.favorites,
          // sRecents: action.recents,
          //sFeatured: action.featured,
          // favorites: action.favorites,
          // recents: action.recents,
          //featured: action.featured,
          originalFeatured: action.featured,
          iconListLoaded: true,
          iconListLoading: false,
          tokens: newTokens
        };
      } else {
        let newFeatured = action.featured.filter(item => {
          if (item.workspace !== undefined && item.workspace.workspaceId !== undefined) {
            return item.workspace.workspaceId === state.activeWS;
          } else {
            return item.workspaceId === state.activeWS;
          }
        });

        newFeatured = orderItems(newFeatured, state.workspaces, state.activeWS);

        return {
          ...state,
          // sFavorites: action.favorites,
          // sRecents: action.recents,
          sFeatured: newFeatured,
          // favorites: action.favorites,
          // recents: action.recents,
          featured: newFeatured,
          originalFeatured: newFeatured,
          iconListLoaded: true,
          iconListLoading: false,
          tokens: newTokens
        };
      }
    case FETCH_APP_MENU_BEGIN:
      return { ...state, appMenuLoaded: false, appMenuLoading: true };
    case FETCH_APP_MENU_SUCCESS:
      return {
        ...state,
        appMenu: action.payload,
        searchAppMenu: action.payload,
        appMenuLoaded: true,
        appMenuLoading: false
      };
    case SET_NEWS_FEED:
      return {
        ...state,
        newsFeed: action.newsFeed
      };
    case SET_REPORT_VIEW:
      return { ...state, reportView: action.payload };
    case SET_DISPLAYED_FEATURED_REPORTS:
      let newFeatured = cloneDeep(action.featured);
      newFeatured = newFeatured.map(rpt => {
        if (rpt.img !== undefined && rpt.img !== "") {
          let ws = "";

          if (rpt.type.match(/Grouping/i)) {
            ws = rpt.path
              .split("/")[0]
              .toLowerCase()
              .replace(/[^a-zA-Z0-9]*/g, "");
          } else {
            if (rpt.workspace !== undefined && rpt.workspace.name !== undefined) {
              ws = rpt.workspace.name.toLowerCase().replace(/[^a-zA-Z0-9]*/g, "");
            } else if (rpt.workspaceName !== undefined) {
              ws = rpt.workspaceName.toLowerCase().replace(/[^a-zA-Z0-9]*/g, "");
            } else {
              for (var wItem of state.workspaces) {
                if (wItem._id === rpt.workspace) {
                  ws = wItem.name.toLowerCase().replace(/[^a-zA-Z0-9]*/g, "");
                }
              }
            }
          }

          state.tokens.forEach(token => {
            if (token.workspaceName === ws && !rpt.img.includes("?st=")) {
              rpt.img = rpt.img + "?" + token.sasToken;
            } else if (token.workspaceName === ws) {
              rpt.img = rpt.img.split("?st=")[0] + "?" + token.sasToken;
            }
          });
        }

        return rpt;
      });

      newFeatured = orderItems(newFeatured, state.workspaces, state.activeWS);

      return {
        ...state,
        featured: newFeatured,
        sFeatured: newFeatured
      };
    case GET_RECENT_AND_FAV_REPORTS:
      let newRecents = cloneDeep(action.recents);
      newRecents = newRecents.map(rpt => {
        if (rpt.img !== undefined && rpt.img !== "") {
          let ws = "";
          if (rpt.type.match(/Grouping/i)) {
            ws = rpt.path
              .split("/")[0]
              .toLowerCase()
              .replace(/[^a-zA-Z0-9]*/g, "");
          } else {
            if (rpt.workspaceName === undefined) {
              ws = rpt.workspace.name.toLowerCase().replace(/[^a-zA-Z0-9]*/g, "");
            } else {
              ws = rpt.workspaceName.toLowerCase().replace(/[^a-zA-Z0-9]*/g, "");
            }
          }

          state.tokens.forEach(token => {
            if (token.workspaceName === ws && !rpt.img.includes("?st=")) {
              rpt.img = rpt.img + "?" + token.sasToken;
            } else if (token.workspaceName === ws) {
              rpt.img = rpt.img.split("?st=")[0] + "?" + token.sasToken;
            }
          });
        }

        return rpt;
      });
      let nFavs = cloneDeep(action.favs);
      nFavs = nFavs.map(rpt => {
        if (rpt.img !== undefined && rpt.img !== "") {
          let ws = "";
          if (rpt.type.match(/Grouping/i)) {
            ws = rpt.path
              .split("/")[0]
              .toLowerCase()
              .replace(/[^a-zA-Z0-9]*/g, "");
          } else {
            if (rpt.workspaceName === undefined) {
              ws = rpt.workspace.name.toLowerCase().replace(/[^a-zA-Z0-9]*/g, "");
            } else {
              ws = rpt.workspaceName.toLowerCase().replace(/[^a-zA-Z0-9]*/g, "");
            }
          }

          state.tokens.forEach(token => {
            if (token.workspaceName === ws && !rpt.img.includes("?st=")) {
              rpt.img = rpt.img + "?" + token.sasToken;
            } else if (token.workspaceName === ws) {
              rpt.img = rpt.img.split("?st=")[0] + "?" + token.sasToken;
            }
          });
        }

        return rpt;
      });

      newRecents = orderItems(newRecents, state.workspaces, state.activeWS);
      nFavs = orderItems(nFavs, state.workspaces, state.activeWS);
      return {
        ...state,
        recents: newRecents,
        sRecents: newRecents,
        favorites: nFavs,
        sFavorites: nFavs
      };
    case SET_DISPLAYED_REPORTS:
      let newRpts = cloneDeep(action.reports);

      newRpts = newRpts.map(rpt => {
        if (rpt.img !== undefined && rpt.img !== "") {
          let ws = "";
          if (rpt.path !== undefined && rpt.path.split("/").length >= 3) {
            ws = rpt.path
              .split("/")[0]
              .toLowerCase()
              .replace(/[^a-zA-Z0-9]*/g, "");
          } else if (rpt.type.match(/Grouping/i)) {
            ws = rpt.path
              .split("/")[0]
              .toLowerCase()
              .replace(/[^a-zA-Z0-9]*/g, "");
          } else {
            if (rpt.workspace !== undefined && rpt.workspace !== null && rpt.workspace.name !== undefined) {
              ws = rpt.workspace.name.toLowerCase().replace(/[^a-zA-Z0-9]*/g, "");
            } else {
              ws = rpt.workspaceName.toLowerCase().replace(/[^a-zA-Z0-9]*/g, "");
            }
          }

          state.tokens.forEach(token => {
            if (token.workspaceName === ws && !rpt.img.includes("?st=")) {
              rpt.img = rpt.img + "?" + token.sasToken;
            } else if (token.workspaceName === ws) {
              rpt.img = rpt.img.split("?st=")[0] + "?" + token.sasToken;
            }
          });
        }

        return rpt;
      });

      return {
        ...state,
        sDisplayRpts: newRpts,
        displayRpts: newRpts
      };
    case SET_REPORTING_APPS:
      return {
        ...state,
        homePageReportingApps: action.payload
      };
    case SET_RA_FOLDER_STATUS:
      return { ...state, hPRAppsFolderStatus: action.payload };
    case SET_DASHBOARD_RPTS:
      return { ...state, dashboardReports: action.payload };
    default:
      return state;
  }
};
