import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.css";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-community";
import "ag-grid-enterprise";
import LiftDetailCellRenderer from "./liftDetailCellRenderer";
import ActionCellRenderer from "./actionCellRenderer";
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(
  "Using_this_AG_Grid_Enterprise_key_( AG-046026 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( TELUS Agriculture Solutions )_is_granted_a_( Single Application )_Developer_License_for_the_application_( TABSInsight )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( TABSInsight )_need_to_be_licensed___( TABSInsight )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 14 October 2024 )____[v2]_MTcyODg2MDQwMDAwMA==995fd12673315dadb6f184b47616c9ca"
);

class AcculiftList extends Component {
  constructor(props) {
    super(props);
    this.state = { api: null };
  }
  onGridReady(event) {
    var that = this;
    setTimeout(function() {
      if (that.props.projects[0].baselines.length > 0) {
        const { selectedBaseline } = that.props;
        if (event.api.getDisplayedRowAtIndex(selectedBaseline.idx) !== undefined) {
          event.api.getDisplayedRowAtIndex(selectedBaseline.idx).setExpanded(true);
        }
      }
    }, 0);
    this.setState({ api: event.api });
    event.api.sizeColumnsToFit();
  }

  componentDidUpdate() {
    var that = this;
    if (this.state.api !== null && this.props.selectedProject.baselines !== undefined) {
      setTimeout(function() {
        if (that.props.selectedProject.baselines.length > 0) {
          if (that.props.selectedBaseline !== null) {
            if (that.state.api.getDisplayedRowAtIndex(that.props.selectedBaseline.idx) !== undefined) {
              that.state.api.getDisplayedRowAtIndex(that.props.selectedBaseline.idx).setExpanded(true);
            }
          }
        }
      }, 0);
    }
  }
  render() {
    return (
      <div
        className="ag-theme-balham project-grid"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          fontSize: "14px"
        }}>
        <AgGridReact
          columnDefs={[
            {
              headerName: "Baseline name",
              field: "name",
              cellRenderer: "agGroupCellRenderer"
            },
            {
              headerName: "Rows",
              field: "rowCount",
              width: 75
            },
            {
              headerName: "Created Date",
              field: "dateCreated",
              width: 100
            },
            {
              headerName: "Weeks",
              field: "periodCount",
              width: 75
            },
            {
              headerName: "Period End",
              field: "periodEnd",
              width: 100
            },
            {
              headerName: "Available Lift Models",
              field: "liftCount",
              width: 130
            },

            {
              headerName: "Actions",
              field: "actions",
              cellRenderer: "actionCellRenderer",
              cellClass: "actions-button-cell",
              width: 100
            }
          ]}
          rowData={this.props.project.baselines}
          masterDetail={true}
          getRowHeight={function(params) {
            if (params.node && params.node.detail && params.data.lifts.length > 0) {
              var offset = 230;
              let rows = Math.ceil(params.data.lifts.length / 3);
              //console.log(rows);
              return rows * offset;
              //var allDetailRowHeight = params.data.lifts.length * 28;
              //return allDetailRowHeight + offset;
            } else if (params.node && params.node.detail) {
              return 230;
            } else {
              // otherwise return fixed master row height
              return 30;
            }
          }}
          detailCellRenderer={"LiftDetailCellRenderer"}
          frameworkComponents={{
            LiftDetailCellRenderer: LiftDetailCellRenderer,
            actionCellRenderer: ActionCellRenderer
          }}
          getRowNodeId={data => {
            return data.id;
          }}
          sortable={true}
          deltaRowDataMode={true}
          suppressContextMenu={true}
          filter={true}
          onGridReady={event => this.onGridReady(event)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lifts: state.acculift.lifts,
  projects: state.acculift.projects,
  searchProjects: state.acculift.searchProjects,
  selectedProject: state.acculift.selectedProject,
  selectedBaseline: state.acculift.selectedBaseline
});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AcculiftList);
