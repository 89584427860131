// containers/App.js
import React, { Component } from "react";
import { Navbar, Nav, NavItem } from "react-bootstrap";
import { connect } from "react-redux";

// import { ic_account_circle } from "react-icons-kit/md/ic_account_circle";
// import { questionCircle } from "react-icons-kit/fa/questionCircle";
import tabsLogo from "img/TABS_Analytics_logo.png";
//import { Bullseye, RightChevron } from "lib/icons";
import "./App.css";
import TabsApps from "./components/tabsApps";
import Profile from "components/profile";
import PPTCart from "components/pptCart";
import Notifications from "components/notifications";
import { Help } from "lib/icons";
import { setIdpInitiatedLogin } from "reducers/authReducer";
import { getApps } from "reducers/appReducer";
import Login from "components/login";
import { ToastContainer } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gotReports: false,
      loadedApps: false,
      listOpen: false
      // apps: [
      //   {
      //     id: 0,
      //     title: "TABS Insight",
      //     link: "/powerBI",
      //     selected: false,
      //     groups: [],
      //     collapsible: true,
      //     type: "powerBI"
      //   },
      //   {
      //     id: 1,
      //     title: "Files",
      //     link: "/files",
      //     selected: false,
      //     groups: [],
      //     collapsible: false,
      //     type: "files"
      //   }
      // {
      //   id: 1,
      //   title: "Accucast",
      //   link: "/accucast",
      //   selected: false
      // }
      // ]
    };
    if (this.props.location.hash.indexOf("access_token") !== -1 || this.props.idpLogin) {
      this.props.setIdpInitiatedLogin(true);
    }
  }
  componentDidMount() {
    if (this.props.auth.isAuthenticated()) {
      if (localStorage.getItem("sso") === undefined || localStorage.getItem("sso") === "false") {
        if (this.props.history.location.pathname.includes("/uploadFile")) {
          this.props.getApps(false, true);
        } else if (this.props.history.location.pathname.includes("/tabsFileShare")) {
          this.props.getApps(false, false, true);
        } else {
          this.props.getApps();
        }
      } else {
        if (this.props.history.location.pathname.includes("/uploadFile")) {
          this.props.getApps(false, true);
        } else if (this.props.history.location.pathname.includes("/tabsFileShare")) {
          this.props.getApps(false, false, true);
        } else {
          this.props.getApps(true);
        }
      }

      this.setState({ loadedApps: true });
    }

    if (
      window.location.pathname !== undefined &&
      window.location.pathname !== "/" &&
      !window.location.pathname.includes("callback") &&
      !window.location.pathname.includes("setClient") &&
      !window.location.pathname.includes("uploadFile") &&
      !window.location.pathname.includes("tabsFileShare")
    ) {
      if (window.location.href.includes("/files/landing?download=")) {
        const file = window.location.href.split("/files/landing?download=")[1];
        localStorage.setItem("continueUrl", "/files/landing?download=" + file);
      } else {
        // console.log("CONTINUE URL SETTING TO " + window.location.pathname);
        // console.log(window.location.href);
        localStorage.setItem("continueUrl", window.location.pathname);
      }
    }
  }
  componentDidUpdate() {
    const { defaultApp, homePage } = this.props;
    var urlChanged = false;
    var defaultFound;
    if (this.props.auth.isAuthenticated()) {
      const { loadedApps } = this.state;
      const continueUrl = localStorage.getItem("continueUrl");

      if (this.props.apps.length === 0 && !loadedApps) {
        if (localStorage.getItem("sso") === undefined || localStorage.getItem("sso") === false) {
          this.props.getApps();
        } else {
          this.props.getApps(true);
        }

        this.setState({ loadedApps: true });
      } else if (continueUrl !== null && continueUrl !== undefined && continueUrl !== "") {
        // console.log(continueUrl);
        // console.log("REPLACE WITH CONTINUE");
        this.props.history.replace(continueUrl);
        localStorage.setItem("continueUrl", "");
      } else if (defaultApp !== null) {
        if (
          this.props.location.pathname === "/" ||
          this.props.location.pathname.includes("callback") ||
          this.props.location.pathname === "/setClient"
          // this.props.location.pathname === "/uploadFile"
        ) {
          if (homePage !== null && homePage !== undefined && Object.entries(homePage).length > 0) {
            if (homePage.link.includes("/landing") || homePage.link.includes("/report/")) {
              this.props.history.push(homePage.link);
            } else {
              defaultFound = this.props.apps.filter(item => {
                return homePage.link.includes(item.link);
              });
              if (defaultFound.length > 0) {
                this.props.history.replace(homePage.link);
              } else if (this.props.apps.length > 0 && this.props.workspaces.length > 0) {
                if (this.props.apps[0].type === "powerBI" || this.props.apps[0].type === "tableau") {
                  this.props.history.replace("/reports");
                } else {
                  this.props.history.replace(this.props.apps[0].link);
                }
              }
            }
          } else if (defaultApp.link !== undefined) {
            if (defaultApp.link === "fileUpload") {
              // debugger;
              // const uploadInboxId = this.props.history.location.hash;
              // this.props.history.replace("/fileUpload" + uploadInboxId);
              urlChanged = true;
            } else if (defaultApp.link === "fileShare") {
              urlChanged = true;
            } else if (defaultApp.link.includes("/landing") && this.props.workspaces.length > 0) {
              defaultFound = this.props.workspaces.filter(item => {
                return defaultApp.name.toLowerCase() === item.name.toLowerCase();
              });
            } else {
              defaultFound = this.props.apps.filter(item => {
                if (item.link === undefined && defaultApp.name !== undefined) {
                  return defaultApp.name.toLowerCase() === item.type.toLowerCase();
                } else {
                  if (
                    item.type === "reporting" &&
                    (defaultApp.link.includes("/landing") || defaultApp.link.includes("/report/"))
                  ) {
                    return true;
                  } else {
                    return defaultApp.link.includes(item.link);
                  }
                }
              });
            }

            if (!urlChanged && defaultFound.length > 0) {
              this.props.history.replace(defaultApp.link);
            } else if (this.props.apps.length > 0 && this.props.workspaces.length > 0) {
              if (this.props.apps[0].type === "powerBI" || this.props.apps[0].type === "tableau") {
                this.props.history.replace("/reports");
              } else {
                this.props.history.replace(this.props.apps[0].link);
              }
            }
          } else {
            let rptsFound = this.props.apps.filter(item => {
              if (item.link === undefined) {
                return false;
              }
              return item.link.includes("/reports");
            });
            if (rptsFound.length > 0) {
              this.props.history.replace("/reports");
            } else if (this.props.apps.length > 0) {
              if (this.props.apps[0].type === "powerBI" || this.props.apps[0].type === "tableau") {
                this.props.history.replace("/reports");
              } else {
                this.props.history.replace(this.props.apps[0].link);
              }
            } else {
              this.props.history.replace("/noPermissions");
            }
          }
        }
      }
    }
  }

  goTo(route) {
    this.props.history.replace(`/${route}`);
  }

  login() {
    this.props.auth.login();
    //this.props.dispath(loginUser());
  }

  logout() {
    this.props.auth.logout();
  }
  renderLogin() {
    const { isAuthenticated } = this.props.auth;
    // if (
    //   this.props.location.hash.indexOf("access_token") !== -1 ||
    //   this.props.idpLogin
    // ) {
    //   //commented this out and put this in the constructor.
    //   //Cannot change state in render.
    //   //this.props.setIdpInitiatedLogin(true);
    //   return <div />;
    if (!isAuthenticated()) {
      // this.props.auth.logout();
      return (
        <div>
          <div className="login-heading">Welcome to TABS Insight Portal!</div>

          <div className="login-wrapper">
            <div className="login-msg">
              You are not logged in. Please log in to continue.
              <div className="login-help">
                <OverlayTrigger
                  placement={"top"}
                  rootClose={true}
                  overlay={
                    <Tooltip id={"login-help"}>
                      Log in using your email address and your Microsoft O365 password. Typically these credentials are the same
                      as your corporate email or computer login.
                    </Tooltip>
                  }
                  trigger={["hover", "focus"]}>
                  <div>
                    <Help className="help" height={"20px"} width={"20px"} color={"rgba(88, 89, 91, 1)"} />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
            <Login auth={this.props.auth} />
          </div>
          <div className="login-info">
            <p>Need Help?</p>
            <p>
              TABS Analytics Support
              <br />
              support@tabsanalytics.com
              <br />
              (203) 925-9162
            </p>
          </div>
        </div>
      );
    }
  }
  toggleList() {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }));
  }
  render() {
    return (
      <div
        className="ta-header"
        style={{
          position: "fixed",
          background: "white",
          top: "0",
          width: "100%",
          zIndex: 100
        }}>
        <Navbar className="ta-navbar">
          <Navbar.Brand style={{ display: "flex", flexDirectin: "column" }} className="ta-apps-brand">
            {!(
              this.props.history.location.pathname.includes("/uploadFile") ||
              this.props.history.location.pathname.includes("/tabsFileShare")
            ) && <TabsApps className="ta-apps" auth={this.props.auth} isAuthenticated={this.props.auth.isAuthenticated} />}
            <div style={{ height: "45px" }}>
              <img src={tabsLogo} alt="Tabs Analytics" className="ta-logo" />
            </div>
          </Navbar.Brand>
          {!(
            this.props.history.location.pathname.includes("/uploadFile") ||
            this.props.history.location.pathname.includes("/tabsFileShare")
          ) && (
            <Nav
              style={{
                flexDirection: "row",
                height: "45px",
                marginBottom: "5px",
                alignItems: "center",
                marginLeft: "auto"
              }}>
              {this.props.logo !== "" ? (
                <NavItem style={{ paddingRight: "20px" }}>
                  <img src={this.props.logo} height="20px" alt="logo" />
                </NavItem>
              ) : null}
              {this.props.inboxId === "" && (
                <>
                  <Notifications />
                  <NavItem
                    onClick={() => {
                      var element = window.document.createElement("a");
                      element.setAttribute("href", "https://support.tabsanalytics.com/portal/");
                      element.setAttribute("target", "_blank");

                      element.style.display = "none";
                      document.body.appendChild(element);

                      element.click();
                      document.body.removeChild(element);
                    }}
                    style={{
                      marginRight: "10px",
                      cursor: "pointer",
                      display: "flex"
                    }}>
                    <Help className="help" height={"20px"} width={"20px"} color={"rgba(88, 89, 91, 1)"} />
                  </NavItem>
                  <PPTCart />
                  {/* <NavItem style={{ paddingRight: "10px" }} eventKey={1} href="#">
              <Icon
                style={{ color: "rgba(88, 89, 91, 1)" }}
                size={24}
                onClick={this.logout.bind(this)}
                icon={questionCircle}
              />
            </NavItem> */}

                  <NavItem href="#">
                    <Profile className="ta-profile" auth={this.props.auth} />
                  </NavItem>
                </>
              )}
            </Nav>
          )}
        </Navbar>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover
        />
        {this.renderLogin()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  workspaces: state.reportList.workspaces,
  apps: state.app.apps,
  logo: state.app.logo,
  searchApps: state.app.searchApps,
  idpLogin: state.auth.idpLogin,
  isAuthed: state.auth.isAuthed,
  appError: state.app.error,
  loading: state.reportList.loading,
  error: state.reportList.error,
  defaultApp: state.app.defaultApp,
  homePage: state.app.homePage,
  inboxId: state.app.inboxId
});
const mapDispatchToProps = dispatch => ({
  setIdpInitiatedLogin(value) {
    dispatch(setIdpInitiatedLogin(value));
  },
  getApps(sso, uploadOnly, fileShare) {
    dispatch(getApps(sso, uploadOnly, fileShare));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
