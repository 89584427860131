import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.css";

//import { Gear } from "lib/icons";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-community";
import "ag-grid-enterprise";
import DataSourceActionCellRenderer from "./dataSourceActionCellRenderer";
import GeoCellRenderer from "./geoCellRenderer";
import ItemCellRenderer from "./itemCellRenderer";
import BaselineDetailCellRenderer from "components/accubase/components/baselineDetailCellRenderer";
import { selectBaseline } from "components/accubase/reducers/accubaseReducer";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(
  "Using_this_AG_Grid_Enterprise_key_( AG-046026 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( TELUS Agriculture Solutions )_is_granted_a_( Single Application )_Developer_License_for_the_application_( TABSInsight )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( TABSInsight )_need_to_be_licensed___( TABSInsight )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 14 October 2024 )____[v2]_MTcyODg2MDQwMDAwMA==995fd12673315dadb6f184b47616c9ca"
);

class AccubaseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: null
    };
  }
  onGridReady(event) {
    var that = this;
    setTimeout(function() {
      if (that.props.projects[0].batch.length > 0) {
        if (event.api.getDisplayedRowAtIndex(that.props.selectedBatch.idx) !== undefined) {
          event.api.getDisplayedRowAtIndex(that.props.selectedBatch.idx).setExpanded(true);
        }
      }
    }, 0);
    this.setState({ api: event.api });
    event.api.sizeColumnsToFit();
  }
  componentDidUpdate() {
    var that = this;
    if (this.state.api !== null && this.props.selectedProject.batch !== undefined) {
      setTimeout(function() {
        if (that.props.selectedProject.batch.length > 0) {
          if (that.props.selectedBatch !== null) {
            if (that.state.api.getDisplayedRowAtIndex(that.props.selectedBatch.idx) !== undefined) {
              that.state.api.getDisplayedRowAtIndex(that.props.selectedBatch.idx).setExpanded(true);
            }
          }
        }
      }, 0);
    }
  }
  render() {
    return (
      <div
        className="ag-theme-balham review-bl-grid"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          // height: "100%",
          alignSelf: "stretch",
          flex: "1"
        }}>
        <AgGridReact
          columnDefs={[
            {
              headerName: "Input Source name",
              field: "name",
              cellRenderer: "agGroupCellRenderer"
            },
            {
              headerName: "Rows",
              field: "rowCount",
              width: 75
            },
            {
              headerName: "Weeks",
              field: "periodCount",
              width: 75
            },
            {
              headerName: "Period End",
              field: "periodEnd",
              width: 100
            },
            {
              headerName: "Created Date",
              field: "dateCreated",
              width: 120
            },
            {
              headerName: "Available Baselines",
              field: "baselineCount",
              width: 130
            },
            {
              headerName: "Item Attribute",
              field: "itemLoaded",
              cellRenderer: "itemCellRenderer",
              width: 120
            },
            {
              headerName: "Geo Attribute",
              field: "geoLoaded",
              cellRenderer: "geoCellRenderer",
              width: 120
            },
            {
              headerName: "Actions",
              field: "actions",
              cellRenderer: "actionCellRenderer",
              cellClass: "actions-button-cell",
              width: 100
            }
          ]}
          rowData={this.props.selectedProject.batch}
          masterDetail={true}
          getRowHeight={function(params) {
            if (params.node && params.node.detail) {
              var offset = 300;
              var allDetailRowHeight = params.data.baselines.length * 28;
              return allDetailRowHeight + offset;
            } else {
              // otherwise return fixed master row height
              return 30;
            }
          }}
          detailCellRenderer={"BaselineDetailCellRenderer"}
          frameworkComponents={{
            BaselineDetailCellRenderer: BaselineDetailCellRenderer,
            actionCellRenderer: DataSourceActionCellRenderer,
            geoCellRenderer: GeoCellRenderer,
            itemCellRenderer: ItemCellRenderer
          }}
          getRowNodeId={data => {
            return data.id;
          }}
          deltaRowDataMode={true}
          sortable={true}
          suppressContextMenu={true}
          filter={true}
          onRowSelected={this.onRowSelected}
          onGridReady={event => this.onGridReady(event)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reInitProjects: state.accubase.reInitProjects,
  selectedProject: state.accubase.selectedProject,
  selectedBatch: state.accubase.selectedBatch,
  projects: state.accubase.projects,
  searchProjects: state.accubase.searchProjects
});
const mapDispatchToProps = dispatch => ({
  selectBaseline(baseline, viewSelected) {
    dispatch(selectBaseline(baseline, viewSelected));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AccubaseList);
