import React, { Component } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/chartsModule";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "./agGrid.css";
import "ag-grid-enterprise";
//import interact from "@interactjs/interact";
import interact from "interactjs";

import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(
  "Using_this_AG_Grid_Enterprise_key_( AG-046026 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( TELUS Agriculture Solutions )_is_granted_a_( Single Application )_Developer_License_for_the_application_( TABSInsight )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( TABSInsight )_need_to_be_licensed___( TABSInsight )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 14 October 2024 )____[v2]_MTcyODg2MDQwMDAwMA==995fd12673315dadb6f184b47616c9ca"
);

export function changeByPercent() {
  //show change by % modal
  this.handleToggleModal();
}

export class AccubaseGrid extends Component {
  constructor(props) {
    super(props);

    this.handleToggleModal = this.handleToggleModal.bind(this);

    this.state = {
      showModal: false,
      chartRef: null,
      chart2Ref: null
    };
  }

  componentDidMount() {
    //Enable drag-to-resize on the grid div

    if (this.props.resizable) {
      interact(".bl-grid-resize")
        .resizable({
          edges: { left: false, right: false, bottom: false, top: true },
          restrictEdges: {
            outer: "parent",
            endOnly: true
          },
          // minimum size
          restrictSize: {
            min: { height: 15 }
            // max: { width: 500 }
          },
          inertia: false
        })
        .on("resizemove", event => {
          event.target.style.height = Math.round(event.rect.height) + "px";
        });
    }
  }
  shouldComponentUpdate(nextProps) {
    if (this.gridApi !== undefined) this.gridApi.refreshCells();
    if (nextProps.rowData.length === this.props.rowData.length) {
      return false;
    }
    return true;
  }
  componentDidUpdate() {
    console.log("Grid Component Did Update");
    this.gridApi.refreshCells();
  }
  // Handle the visibility of the modal.
  // If `state.showModal` is false, sets it to true,
  // if is true, sets it to false.
  handleToggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }
  handleValue(value) {
    this.increaseXPercent(value / 100);
    this.setState({ showModal: !this.state.showModal });
  }
  onGridReady(params) {
    this.gridApi = params.api;

    this.params = params;
    this.chartRef = null;
    this.context = { thisComponent: this };
    this.gridColumnApi = params.columnApi;
    if (this.props.gridReady !== undefined) {
      this.props.gridReady(params);
    }

    //this.gridApi.sizeColumnsToFit();
  }

  getContextMenuItems = params => {
    var result = [
      // {
      //   // custom item
      //   name: "Adjust by %",
      //   action: function() {
      //     params.context.thisComponent.changeByPercent();
      //   },
      //   cssClasses: ["redFont", "bold"]
      // },
      // {
      //   // custom item
      //   name: "Fill Down ",
      //   action: function() {
      //     params.context.thisComponent.fillDown();
      //   },
      //   cssClasses: ["redFont", "bold"]
      // },
      // {
      //   // custom item
      //   name: "Fill Across ",
      //   action: function() {
      //     params.context.thisComponent.fillAcross();
      //   },
      //   cssClasses: ["redFont", "bold"]
      // },
      // {
      //   // custom item
      //   name: "Fill Selection ",
      //   action: function() {
      //     params.context.thisComponent.fillSelection();
      //   },
      //   cssClasses: ["redFont", "bold"]
      // },
      // "separator",
      // built in copy item
      "copy",
      "copyWithHeaders",
      "separator",
      "autoSizeAll"
      //"excelExport"
    ];

    return result;
  };
  getMainMenuItems(params) {
    //remove the tool panel from the menu
    let finalMenuItem = [];
    let itemsToExclude = "toolPanel";
    params.defaultItems.forEach(function(item) {
      if (itemsToExclude.indexOf(item) < 0) {
        finalMenuItem.push(item);
      }
    });
    return finalMenuItem;
  }

  render() {
    return (
      <div
        className={"ag-theme-balham review-bl-grid " + (this.props.resizable ? "bl-grid-resize" : "bl-grid-noresize")}
        style={{
          width: "100%"
        }}>
        <AgGridReact
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true
          }}
          pivotMode={false}
          enableCharts={false}
          sideBar={{
            toolPanels: [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivotMode: true
                }
              },
              {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel",
                toolPanelParams: {}
              }
            ]
          }}
          enableRangeSelection={true}
          groupMultiAutoColumn={false}
          onModelUpdated={this.modelUpdated}
          // headerHeight={50}
          rowSelection={"multiple"}
          rowDeselection={true}
          //refreshCells={true}
          //suppressContextMenu={false}
          // toolPanelSuppressSideButtons={true}
          getMainMenuItems={this.getMainMenuItems}
          getContextMenuItems={this.getContextMenuItems}
          //singleClickEdit={true}
          context={{ thisComponent: this }}
          onFirstDataRendered={params => {
            //this.renderCharts(params);
          }}
          onFilterChanged={e => {
            this.props.onFilterChanged(e);
          }}
          onGridReady={this.onGridReady.bind(this)}
          {...this.props}
        />
      </div>
    );
  }
}
