import axios from "axios";
import { cloneDeep } from "lodash";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  accessToken: {},
  loading: false,
  error: null,
  pageNames: [],
  personalBookmarks: [],
  sPersonalBookmarks: [],
  bookmarkFilter: ""
};

/* -------------------- ACTION TYPES -------------------- */
const CLEAR_ACCESS_TOKEN = "CLEAR_ACCESS_TOKEN";
const FETCH_ACCESS_TOKEN_BEGIN = "FETCH_ACCESS_TOKEN_BEGIN";
const FETCH_ACCESS_TOKEN_SUCCESS = "FETCH_ACCESS_TOKEN_SUCCESS";
const FETCH_ACCESS_TOKEN_FAILURE = "FETCH_ACCESS_TOKEN_FAILURE";
const SET_PERSONAL_BOOKMARKS = "SET_PERSONAL_BOOKMARKS";
const SET_SEARCH_BOOKMARKS = "SET_SEARCH_BOOKMARKS";

/* -------------------- ACTION CREATORS -------------------- */

const clear_access_token = () => {
  return { type: CLEAR_ACCESS_TOKEN, payload: {} };
};

const fetchAccessTokenBegin = () => ({
  type: FETCH_ACCESS_TOKEN_BEGIN
});
const fetchAccessTokenSuccess = accessToken => {
  return { type: FETCH_ACCESS_TOKEN_SUCCESS, payload: accessToken };
};
const fetchAccessTokenError = error => ({
  type: FETCH_ACCESS_TOKEN_FAILURE,
  payload: { error }
});
const setSearchBookmarks = bookmarks => {
  return { type: SET_SEARCH_BOOKMARKS, payload: bookmarks };
};
const setPersonalBookmarks = bookmarks => {
  return { type: SET_PERSONAL_BOOKMARKS, payload: bookmarks };
};

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const getAccessToken = (groupId, reportId, datasetId, mode) => {
  return dispatch => {
    dispatch(fetchAccessTokenBegin());
    return axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/getToken`,
        {
          groupId,
          reportId,
          datasetId,
          mode
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchAccessTokenSuccess(response.data));
        return response.data;
      })
      .catch(error => {
        dispatch(fetchAccessTokenError(error));
      });
  };
};
export const updatePersonalBookmarks = bookmarks => {
  return dispatch => {
    dispatch(setPersonalBookmarks(bookmarks));
  };
};
export const getPersonalBookmarks = reportId => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/getPersonalBookmarksForReport`,
        {
          reportId
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        // console.log("GOT PERONSAL BOOKMARKS");

        // console.log(response);
        dispatch(setPersonalBookmarks(response.data.pBookmarks));
      });
  };
};
export const clearAccessToken = () => {
  return dispatch => {
    dispatch(clear_access_token());
  };
};

export const filterBookmarks = (filter, bookmarks) => {
  return dispatch => {
    if (filter === "") {
      dispatch(setSearchBookmarks(bookmarks));
    } else {
      let newBookmarks = cloneDeep(bookmarks);

      dispatch(
        setSearchBookmarks(
          newBookmarks.filter(bMark => {
            return bMark.name.toLowerCase().includes(filter.toLowerCase());
          })
        )
      );
    }
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload, loading: true };

    case FETCH_ACCESS_TOKEN_BEGIN:
      return { ...state, loading: true, error: null, pageNames: [] };
    case FETCH_ACCESS_TOKEN_SUCCESS:
      let newPN;
      if (action.payload.pageNames !== undefined) {
        newPN = action.payload.pageNames.map(item => {
          return item.order + "|" + item.Name + "|" + item.displayName;
        });
      }
      return {
        ...state,
        accessToken: action.payload,
        loading: false,
        pageNames: newPN
      };
    case FETCH_ACCESS_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        reportConfig: []
      };
    case SET_PERSONAL_BOOKMARKS:
      return {
        ...state,
        personalBookmarks: action.payload,
        sPersonalBookmarks: action.payload
      };
    case SET_SEARCH_BOOKMARKS:
      return { ...state, sPersonalBookmarks: action.payload };
    default:
      return state;
  }
};
